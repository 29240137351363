// const oktaAuthConfig = {
//   issuer: `https://arrivelogistics.oktapreview.com/oauth2/default`,
//   clientId: "0oazh9anv5Fs3fDnF0h7",
//   redirectUri: window.location.origin + '/login/callback',
//   scopes: ['openid', 'profile', 'email'],
//   pkce: true
// };
//
// export { oktaAuthConfig };


export default function oktaConfig() {
  let clientId;
  let baseUrl;

  switch (window.origin) {
    case 'https://quickclaims.arrivelogistics.com':
      clientId = process.env.REACT_APP_OKTA_CLIENT_ID
      baseUrl = process.env.REACT_APP_OKTA_BASE_URL
      break

    default:
      clientId = process.env.REACT_APP_TEST_OKTA_CLIENT_ID
      baseUrl = process.env.REACT_APP_TEST_OKTA_BASE_URL
      break
  }

  return  {
    clientId: clientId,
    issuer: baseUrl + '/oauth2/default',
    redirectUri: window.origin + '/login/callback',
    scopes: ['openid', 'profile', 'email'],
    tokenManager: {
      storage: 'sessionStorage'
    },
    cookies: {
      secure: process.env.NODE_ENV !== 'development'
    },
    baseUrl: baseUrl,
    authParams: {},
  }
}