import React from "react";
import {createMuiTheme, CssBaseline, ThemeProvider} from "@material-ui/core";
import PageContent from "../components/PageContent";

// export const ThemeContext = createContext();

export function AppTheme(props) {

  const defaultTheme = createMuiTheme({
    palette: {
      primary: {
        light: "#e0f3fb",
        main: "#37A9E7",
        dark: "#039ddd"
      }
    },
    typography: {
      fontSize: 12
    },
    overrides: {
      MuiButton: {
        textPrimary: {
          color: "#2aaae2"
        }
      }
    }
  });

  return (
    <ThemeProvider theme={defaultTheme}>
      <CssBaseline/>
      <PageContent>
        {props.children}
      </PageContent>
    </ThemeProvider>
  );
}