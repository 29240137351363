import React, {createContext, useState} from "react";


export const GlobalContext = createContext({})


export function GlobalProvider(props) {
  const [userInfo, setUserInfo] = useState({username: '', name: ''})
  const [data, setData] = useState();

  return (
    <GlobalContext.Provider value={{userInfo, setUserInfo, data, setData}}>
      {props.children}
    </GlobalContext.Provider>
  )
}