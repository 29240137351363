// export const claim_type_options = [
//   {code: "loss and damage", label: "Loss and Damage"},
//   {code: "overcharge", label: "overcharge"},
//   {code: "return authorization", label: "return authorization"},
//   {code: "vendor", label: "vendor"},
// ]

export const reason_types = [
  {code: "AMCF", label: "Accident - Major Collision (Injury/Fatality)"},
  {code: "AMCI", label: "Accident - Major Collision (No Injury)"},
  {code: "CD", label: "Concealed Damage"},
  {code: "Chargeback", label: "Vendor Chargeback"},
  {code: "Contaminat", label: "Contamination"},
  {code: "CS", label: "Concealed Shortage"},
  {code: "DB", label: "Double Brokering"},
  {code: "DERAIL", label: "Derailment"},
  {code: "DL", label: "Delay"},
  {code: "EH", label: "Environmental Hazard"},
  {code: "FIR", label: "Fire"},
  {code: "HS", label: "Hostage"},
  {code: "INFEST", label: "Infestation"},
  {code: "LDS", label: "Load Shifted"},
  {code: "Lost", label: "Lost"},
  {code: "LSSL", label: "Loss of Sale"},
  {code: "MB", label: "Mechanical Breakdown"},
  {code: "ML", label: "Mislabeled"},
  {code: "ND", label: "Non-Delivery"},
  {code: "NR", label: "Not Returned"},
  {code: "OV", label: "Overage"},
  {code: "PD", label: "Property Damage"},
  {code: "PL", label: "Pilferage"},
  {code: "PNP", label: "POD Not Provided"},
  {code: "PT", label: "Pilferage Tampering"},
  {code: "RA", label: "Risk Assessment"},
  {code: "RP", label: "Repower"},
  {code: "SCL", label: "Scammed Load"},
  {code: "SHT", label: "Shortage"},
  {code: "SISS", label: "Seal Issue"},
  {code: "SL", label: "Stolen Load"},
  {code: "SLFR", label: "Stolen Load Fraud"},
  {code: "SLR", label: "Stolen Load Recovered"},
  {code: "STLFR", label: "Stolen Load Fraud Recovered"},
  {code: "TAF", label: "Temperature Abuse Frozen"},
  {code: "TAH", label: "Temperature Abuse Hot"},
  {code: "TD", label: "Trailer Damage"},
  {code: "VD", label: "Visible Damage"},
  {code: "WATD", label: "Water Damage - Visible"}
]