import React, {useState} from "react";
import ClaimReason from "./ClaimReason";
import Button from "@material-ui/core/Button";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import {useOktaAuth} from "@okta/okta-react";
import createEZClaim from "../api/createEZClaim";
import ClaimReference from "./ClaimReference";
import ClaimComment from "./ClaimComment";
import LinearProgress from "@material-ui/core/LinearProgress";
import Typography from "@material-ui/core/Typography";

const useStyles = makeStyles((theme) => createStyles({
    root: {
      display: "flex",
      flexDirection: "column",
    },
    searchButton: {
      margin: theme.spacing(1),
      color: 'white',
    },
    buttons: {
      display: 'flex',
      justifyContent: 'space-around'
    },
    loader: {
      margin: theme.spacing(2),
      textAlign: 'center'
    },
    loadingBar: {
      alignSelf: 'center',
    }
  }
))

export default function ClaimForm(props) {
  const classes = useStyles();
  const {loadNumber, setData, setMessage, setOpen, setLevel, allReferences} = props;
  const {oktaAuth} = useOktaAuth();

  const [loading, setLoading] = useState(false);
  const [claimReason, setClaimReason] = useState("");
  const [referenceNumber, setReferenceNumber] = useState("")
  const [description, setDescription] = useState("")

  function alertHandler(level, message) {
    setOpen(false)
    setLevel(level);
    setMessage(message);
    setOpen(true)
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const accessToken = oktaAuth.getAccessToken();
    try {
      const response = await createEZClaim(loadNumber, claimReason, description, referenceNumber, accessToken);
      setData(null)
      alertHandler('success', `Claim ${response.data.claim_number} has been created.`)
    } catch (e) {
      alertHandler('error', e.response.data.error)
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleCancel = (e) => {
    e.preventDefault();
    setData(null)
  }

  return (
    <form className={classes.root}>
      <ClaimReason claimReason={claimReason} setClaimReason={setClaimReason}/>
      <ClaimReference allReferences={allReferences} reference={referenceNumber} setReference={setReferenceNumber}/>
      <ClaimComment description={description} setDescription={setDescription}/>

      <div>
        {!loading
          ?
          <div className={classes.buttons}>
            <Button className={classes.searchButton} onClick={handleSubmit} size={'medium'} color={'primary'}
                    variant={'contained'}
                    disabled={loading || !claimReason || !description}>Submit</Button>
            <Button className={classes.searchButton} size={'medium'} onClick={handleCancel} color={'secondary'}
                    variant={'contained'}>Cancel</Button>
          </div>
          :
          <div className={classes.loader}>
            <div>
              <Typography variant={'caption'}>
                processing...
              </Typography>
            </div>
            <div>
              <LinearProgress className={classes.loadingBar}/>
            </div>
          </div>
        }
      </div>
    </form>
  )
}