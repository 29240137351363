import React, {useContext, useEffect, useState} from 'react';
import {useOktaAuth} from "@okta/okta-react";
import {GlobalContext} from "../contexts/GlobalContext";
import SearchLoad from "./SearchLoad";
import ClaimBase from "./ClaimBase";
import AlertBanner from "./AlertBanner";


export function HomeContent() {
  const {authState, oktaAuth} = useOktaAuth();
  const {setUserInfo, data, setData} = useContext(GlobalContext);
  const [open, setOpen] = useState(false)
  const [level, setLevel] = useState("")
  const [message, setMessage] = useState("")

  useEffect(()=> {
    if (authState.isAuthenticated){
      oktaAuth.getUser().then(res => {
        setUserInfo({username: res.email, name: res.name})
      })
    }
  }, [authState.isAuthenticated, oktaAuth, setUserInfo])

  return (
    <div style={{display: "flex", alignContent: "center", justifyContent: "center"}}>
      {
        data
        ?
        <ClaimBase data={data} setData={setData} setOpen={setOpen} setLevel={setLevel} setMessage={setMessage}/>
        :
        <SearchLoad setData={setData}/>
      }
      {open && <AlertBanner open={open} setOpen={setOpen} level={level} message={message}/>}
    </div>
  );
}
