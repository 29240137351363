import React from 'react'
import {useOktaAuth} from '@okta/okta-react';
import {HomeContent} from "./HomeContent";
import TopBanner from "./TopBanner";
import Grid from "@material-ui/core/Grid";


export default function Home() {
  const {authState} = useOktaAuth();

  return (
    authState.isAuthenticated
      ?
        <Grid container direction={'column'} style={{height: "100vh"}}>
          <Grid item>
            <TopBanner style={{height: "10vh"}}/>
          </Grid>
          <Grid container item justify={'center'} alignItems={'center'} style={{height: "90vh"}}>
            <HomeContent/>
          </Grid>
        </Grid>
      :
      null
  )
}
