import React from 'react';
import TextField from "@material-ui/core/TextField";
import {makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing(1),
    marginTop: theme.spacing(2),
    width: '100%'
  },
  textArea: {
    width: 200
  }
}));


export default function ClaimComment(props) {
  const classes = useStyles();
  const {description, setDescription} = props;

  const handleChange = (e) => {
    setDescription(e.target.value)
  }

  return (
    <div className={classes.root}>
      <TextField
        className={classes.textArea}
        id="outlined-multiline-static"
        label="Description"
        multiline
        rows={2}
        rowsMax={10}
        defaultValue=""
        variant="outlined"
        value={description}
        onChange={handleChange}
      />
    </div>

  )
}