import React from "react";
import {Divider, List, ListItem, ListItemText} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => createStyles({
  root: {
    margin: theme.spacing(1),
    minHeight: 400 - theme.spacing(4)
  },
  header: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(-2)
  }
}))

export default function ClaimData(props) {
  const classes = useStyles();
  const {data} = props;
  const customerLoc = `${data.Customer.Addresses[0].City}, ${data.Customer.Addresses[0].StateCode} ${data.Customer.Addresses[0].PostalCode}`
  const shipperLoc = `${data.Shipper.Address.City}, ${data.Shipper.Address.StateCode} ${data.Shipper.Address.PostalCode}`
  const consigneeLoc = `${data.Consignee.Address.City}, ${data.Consignee.Address.StateCode} ${data.Consignee.Address.PostalCode}`

  let carrierLoc = 'No Address found';
  if (data.Carrier.Addresses.length > 0) {
    carrierLoc = `${data.Carrier.Addresses[0].City}, ${data.Carrier.Addresses[0].StateCode} ${data.Carrier.Addresses[0].PostalCode}`
  }

  return (
      <List className={classes.root} component={Paper}>
        <Typography className={classes.header} variant={'subtitle1'}><strong>LoadNumber</strong></Typography>
        <ListItem>
          <ListItemText primary={data.LoadNumber}/>
        </ListItem>
        <Divider/>

        <Typography className={classes.header} variant={'subtitle1'}><strong>Customer</strong></Typography>
        <ListItem>
          <ListItemText primary={data.Customer.Name} secondary={customerLoc}/>
        </ListItem>
        <Divider/>

        <Typography className={classes.header} variant={'subtitle1'}><strong>Carrier</strong></Typography>
        <ListItem>
          <ListItemText primary={data.Carrier.Name} secondary={carrierLoc}/>
        </ListItem>
        <Divider/>

        <Typography className={classes.header} variant={'subtitle1'}><strong>Shipper</strong></Typography>
        <ListItem>
          <ListItemText primary={data.Shipper.Name} secondary={shipperLoc}/>
        </ListItem>
        <Divider/>

        <Typography className={classes.header} variant={'subtitle1'}><strong>Consignee</strong></Typography>
        <ListItem>
          <ListItemText primary={data.Consignee.Name} secondary={consigneeLoc}/>
        </ListItem>
      </List>
  )
}