import React, {useState} from 'react';
import {useOktaAuth} from "@okta/okta-react";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {createStyles, makeStyles} from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import getLoadData from "../api/getLoadData";


const useStyles = makeStyles((theme) => createStyles({
    root: {
      display: 'flex',
      alignItems: 'center',
      background: "rgba(255, 255, 255, .7)",
      height: 200,
      width: 250,
      flexDirection: "column",
      boxShadow: "1px 1px 1px rgba(0, 0, 0, .1)",
      outline: "1px solid rgba(0, 0, 0, .05)"
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    inputArea: {
      margin: "1rem"
    },
    searchButton: {
      color: 'white'
    },
    messageArea: {
      marginTop: "1rem",
      fontSize: 14,
      textAlign: "center"
    },
    buttonProgress: {
      color: "#2aaae2",
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
  }),
);


export default function SearchLoad(props) {
  const {setData} = props;
  const classes = useStyles();
  const {oktaAuth} = useOktaAuth();

  const [loading, setLoading] = useState(false);
  const [loadNumber, setLoadNumber] = useState("");
  const [err, setErr] = useState({isError: false, errorMessage: ""});

  const onChange = (e) => {
    setLoadNumber(e.target.value);
  }

  const onClick = async (e) => {
    e.preventDefault();
    setLoading(true);
    const accessToken = oktaAuth.getAccessToken();
    try {
      let response = await getLoadData(accessToken, loadNumber);
      setData(response.data);
    } catch (e) {
      setErr({isError: true, errorMessage: e.response.data.error});
      setLoading(false)
    }
  }

  return (
    <div className={classes.root}>
      <Typography className={classes.messageArea} variant={'h6'}>Search for Load to get started</Typography>
      <TextField className={classes.inputArea} label={"Load Number"} variant={"outlined"} value={loadNumber}
                 onChange={onChange} error={err.isError} helperText={err.errorMessage}/>

      <div className={classes.wrapper}>
      <Button className={classes.searchButton} onClick={onClick} size={'medium'} color={'primary'} variant={'contained'}
              disabled={loading}>Search</Button>
        {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
      </div>
    </div>
  );
}