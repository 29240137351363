import React from 'react';
import {createStyles, makeStyles} from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import ClaimForm from "./ClaimForm";
import ClaimData from "./ClaimData";

const useStyles = makeStyles((theme) => createStyles({
    root: {
      display: 'flex',
      // alignItems: 'center',
      background: "rgba(255, 255, 255, .7)",
      minHeight: 400,
      width: 500,
      flexDirection: "column",
      boxShadow: "1px 1px 1px rgba(0, 0, 0, .1)",
      outline: "1px solid rgba(0, 0, 0, .05)"
    },
    wrapper: {
      margin: theme.spacing(1),
      position: 'relative',
    },
    searchButton: {
      color: 'white',
    },
    buttonProgress: {
      color: "#2aaae2",
      position: 'absolute',
      top: '50%',
      left: '50%',
      marginTop: -12,
      marginLeft: -12,
    },
    dataSide: {
      background: "#f4f5f5",
      margin: theme.spacing(1),
      minHeight: 400 - theme.spacing(2)
    }
  }
))

export default function ClaimBase(props) {
  const {data, setData, setOpen, setMessage, setLevel} = props;
  const classes = useStyles();

  return (
    <>
      <Paper className={classes.root}>
        <Grid container>

          <Grid className={classes.dataSide} item xs={6}>
            <ClaimData data={data}/>
          </Grid>

          <Grid item xs={5}>
            <ClaimForm hasPod={data.hasPod} setData={setData}
                       setOpen={setOpen} setMessage={setMessage}
                       setLevel={setLevel} loadNumber={data.LoadNumber}
                       allReferences={data.ReferenceNumbers}
            />
          </Grid>
        </Grid>
      </Paper>
    </>
  )
}