import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import logo from '../images/light-logo.svg'

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    color: "#efefef"
  },
}));

export default function TopBanner() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
    <AppBar position="static">
      <Toolbar>
        <img src={logo} alt='Arrive-Logistics' width={300}/>
        <Typography variant="h6" className={classes.title} align={"right"}>
          <strong>
            QuickClaims
          </strong>
        </Typography>
      </Toolbar>
    </AppBar>
    </div>
  )
}