import React from 'react';
import {createStyles, makeStyles} from "@material-ui/core/styles";

const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      display: 'flex',
      background: theme.palette.background.default,
      height: "100vh",
    },
  }),
);

export default function PageContent(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      {props.children}
    </div>
  )
}