import React from 'react';
import {SecureRoute, Security, LoginCallback} from '@okta/okta-react';
import {OktaAuth, toRelativeUrl} from '@okta/okta-auth-js';
import {useHistory, Route, BrowserRouter as Router, Switch} from 'react-router-dom';
import oktaAuthConfig from "./config";
import Home from "./components/Home";
import {AppTheme} from "./contexts/ThemeContext";
import {GlobalProvider} from "./contexts/GlobalContext";

const auth = oktaAuthConfig()
const oktaAuth = new OktaAuth(auth);

const SecureApp = () => {
  const history = useHistory();
  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin))
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      <Switch>
        <Route path="/login/callback" component={LoginCallback}/>
        <SecureRoute path="/" component={Home}/>
      </Switch>
    </Security>
  );
};

function App() {
  return (
    <div>
      <AppTheme>
        <Router>
          <GlobalProvider>
            <SecureApp/>
          </GlobalProvider>
        </Router>
      </AppTheme>
    </div>
  )
}

export default App;
