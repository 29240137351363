import React from 'react';
import {makeStyles} from '@material-ui/core/styles';
import FormControl from "@material-ui/core/FormControl";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import {reason_types} from "../constants";


const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selectArea: {
    maxHeight: 300
  }
}));


export default function ClaimTypeSelect(props) {
  const {claimReason, setClaimReason} = props;
  const classes = useStyles();

  const handleChange = (event) => {
    setClaimReason(event.target.value);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="claim-type-label">Claim Reason</InputLabel>
        <Select
          className={classes.selectArea}
          labelId="claim-type-label"
          value={claimReason}
          onChange={handleChange}
          label="Claim Type"
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          {reason_types.map(option => <MenuItem key={option.code} value={option.label}>{option.label}</MenuItem>)}
        </Select>
      </FormControl>
    </div>)
}